<template>
    <vx-card title="All Season Passes1">
      <template slot="actions">
         <vs-button @click="editSelected({})">Add</vs-button>
         <AddSeasonPass v-if="openAddWindow" :selectedPass="selectedPass" :selectedDateConfig="selectedDateConfig" :openAddWindow="openAddWindow" @loadSeasonPass="loadSeasonPass" @onClose="handleClose"></AddSeasonPass>
      </template>
        <vs-table :sst="true" @sort="handleSort" stripe hoverFlat :data="seasonPassList" v-if="seasonPassList.length">
            <template #thead>
                <vs-th class="width1"></vs-th>
                <vs-th sort-key="name">Season</vs-th>
                <vs-th sort-key="startDate">Period</vs-th>
                <vs-th sort-key="priceIncGst">Price</vs-th>
                <vs-th class="width1">Color</vs-th>
                <vs-th class="width1"></vs-th>
            </template>
            <template slot-scope="{data}">
                <vs-tr v-for="(season, index) in seasonPassList" :key="index" :data="season">
                    <vs-td><vs-button color="dark" @click="editSelected(season)">Edit</vs-button></vs-td>
                    <vs-td>{{season.name}}</vs-td>
                    <vs-td>{{ season.startDate | formatLongDate }} to {{ season.endDate | formatLongDate }}</vs-td>
                    <vs-td>{{season.priceIncGst | formatCurrency}}</vs-td>
                    <vs-td>
                        <vs-avatar :color="season.statusColor ? season.statusColor : '#ccc'" size="large" icon-pack="feather" icon="icon-credit-card" />
                    </vs-td>
                    <vs-td><vs-button color="danger" @click="handleDelete(season.id)">Delete</vs-button></vs-td>
                </vs-tr>
            </template>
        </vs-table>
        <NoData v-else class="mt-base" :headTitle="noData.title" :description="noData.description" ></NoData>
    </vx-card>
</template>

<script>
import {
    productData
} from "../../store/api/product";
import AddSeasonPass from './AddSeasonPass';
import _ from 'lodash';

export default {
    data() {
        return {
            selectedPass: undefined,
            selectedDateConfig: undefined,
            openAddWindow: false,
            seasonPassList: [],
            noData: {
              title: "No Records Found"
            }
        }
    },
    components: {
        AddSeasonPass
    },
    async created() {
        await this.loadSeasonPass()
    },
    methods: {
        async loadSeasonPass() {
            this.seasonPassList = await productData.SearchProducts();
        },
        handleClose() {
            if (this.openAddWindow) {
                this.openAddWindow = !this.openAddWindow;
                this.loadSeasonPass();
            }
        },
        editSelected(tr) {
            this.selectedPass = tr;
            if(tr){
              this.selectedDateConfig = {
                maxDate : this.selectedPass.startDate,
                endDate : this.selectedPass.endDate
              }
            }
            if (!this.openAddWindow) {
                this.openAddWindow = !this.openAddWindow;
            }
        },
        async handleDelete(id) {
            if (confirm("are you sure ? ")) {
                var result = await productData.RemoveProduct(id);
                await this.loadSeasonPass();
            }
        },
        handleSelectedPass() {
            this.selectedPass = value;
        },
         handleSort(key, active) {
          this.seasonPassList = _.orderBy(this.seasonPassList, key, active);
        },

    }
}
</script>
